.toggleGroup {
    overflow: hidden;
    position: relative;
}

.toggleGroup-toggle {
    float: left;
}

.toggleGroup-toggle > input[type="radio"] {
    position: absolute;
    left: -999px;
    top: -999px;
}

.toggleGroup-toggle > label {
    display: block;
    padding: 5px 8px;
    text-align: center;
    border: 1px solid;
    border-right-width: 0;
    cursor: pointer;
    color: #dddddd;
    background-color: #aaaaaa;
    border-color: #aaaaaa;
}

.toggleGroup-toggle > label:hover {
    color: #ffffff;
    background-color: #bbbbbb;
}

.toggleGroup-toggle:first-child > label {
    border-radius: 5px 0 0 5px;
}

.toggleGroup-toggle:last-child > label {
    border-radius: 0 5px 5px 0;
    border-right-width: 1px;
}

.toggleGroup-toggle > input:checked + label {
    color: #999999;
    background-color: #eeeeee;
    cursor: default;
}
