.truncatedCells {
    display: flex;

    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    > * + * {
        margin-left: 5px;
    }
}

.truncatedCells_split {
    > * {
        flex-basis: 50%;
    }
}

.mix--truncatedCells_align {
    align-items: center;
}

.truncatedCells-freeze {
    flex-grow: 0;
    flex-basis: auto;
}
