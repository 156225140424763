.tabs {
    @include clearfix();

    list-style: none;
    margin: 0;
    padding: 0 0 0 10px;

    > * {
        float: left;
        margin: 0;
        border-radius: 5px 5px 0 0;
        padding: 10px 20px;
        background: #ffffff;
        color: #cccccc;
        cursor: pointer;

        // These styles better moved to a content object
        font-size: 18px;
        font-weight: bold;

        &:hover {
            background: #efefef;
            color: #aaaaaa;
        }
    }

    > * + * {
        margin-left: 5px;
    }

    > .active {
        background: #eeeeee;
        color: #5f5f5f;
        cursor: default;
    }
}


