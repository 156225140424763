.wysiwyg {
    @import "../base/reset";

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 { font-weight: bold; }

    h1 { font-size: 1.4em; }
    h2 { font-size: 1.3em; }
    h3 { font-size: 1.25em; }
    h4 { font-size: 1.2em; }
    h5 { font-size: 1.15em; }
    h6 { font-size: 1.1em; }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
        margin-bottom: 0.5em;
    }

    b,
    strong { font-weight: bold; }

    i,
    em { font-style: italic; }

    ul {
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 25px;

        ul {
            list-style-type: circle;

            ul {
                list-style-type: square;

                ul {
                    list-style-type: disc;

                    ul {
                        list-style-type: circle;

                        ul {
                            list-style-type: disc;
                        }
                    }
                }
            }
        }
    }

    ol {
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 25px;

        ol {
            list-style-type: upper-alpha;

            ol {
                list-style-type: upper-roman;

                ol {
                    list-style-type: lower-alpha;

                    ol {
                        list-style-type: lower-roman;

                        ol {
                            list-style-type: decimal;
                        }
                    }
                }
            }
        }
    }
}
