.inputSuffix {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputSuffix-input {
    order: 1;
    flex-grow: 1;
}

.inputSuffix-suffix {
    order: 2;
    flex-grow: 0;
    padding-left: 5px;
}
