.instructions {
    font-family: serif;
    font-style: italic;
    margin: 20px 0;

    p {
        margin: 0;
    }

    p + p {
        margin-top: 10px;
    }
}
