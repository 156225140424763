.iti {
    width: 100%;
}

.iti__flag { background-image: url("/web3/build/base/images/flags.png"); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag { background-image: url("/web3/build/base/images/flags@2x.png"); }
}

.iti__country-list {
    z-index: 3;
}

.iti__country-list li {
    list-style-type: none;
    font-weight: normal;
    margin: 0;
}

.iti__flag-container {
    float: none;
    width: auto;
}

.iti__country-name {
    color: #5f5f5f;
}
