.locationTag {
    padding: 1px 7px;
    margin-right: -4px;
    font-size: 10pt;

    &.rework {
        background-color: rgb(253, 2, 155);
        color: #ffffff;
    }
}
