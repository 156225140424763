.package {
    display: flex;
    flex-direction: row;
    padding: 5px 0;
}

.package > * {
    flex-grow: 1;
}

.package > * + * {
    margin-left: 15px;
}

.package-col_icon {
    flex-grow: 0;
    padding-top: 28px;
}
