.collaterateInfo {
    background: #d88200;
    background: linear-gradient(30deg, #d88200 0%, #f2a430 38%, #f2a430 70%, #edaf5e 100%);
    box-shadow: 0 -2px 8px 0 inset rgba(0, 0, 0, 0.3);
    padding: 0 20px;
}

.collaterateInfo-message {
    display: flex;
    align-items: baseline;
    padding: 30px 10px;
}

.collaterateInfo-message + .collaterateInfo-message {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.collaterateInfo-message-time {
    font-size: 9px;
    color: white;
    flex-grow: 0;
    white-space: nowrap;
    font-style: italic;
    margin-right: 25px;
}

.collaterateInfo-message-bd {
    margin: 0;
    font-size: 15px;
    line-height: 1.4;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    color: white;
}
