.blockFilters {
    display: flex;
    flex-wrap: wrap;

    > * {
        // flex-grow: 1;
        min-width: 190px;
        margin-right: 10px;
    }
}
