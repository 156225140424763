.uib-datepicker-popup > li {
    margin-left: 0;
    @debug "TODO: This override only exists due to lack of reset. (Margin on left of <li> elements) Remove when reset in place.";
}

// .uib-daypicker is the table generated by the directive
.uib-daypicker {
    margin-bottom: 0;
    @debug "TODO: This override only exists due to lack of reset. (Margin on bottom of <table> elements) Remove when reset in place.";

    .text-muted {
        opacity: 0.5;
    }

    thead th {
        text-align: center;
    }

    tbody td {
        padding: 1px 3px;
    }
}

.uib-day button {
    border: 0;
    padding: 4px 8px;

    &.active {
        box-shadow: none;
    }
}
