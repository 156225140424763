.productInfoBar-hd {
    display: flex;
    flex-direction: row;
    font-size: 14px;
}

.productInfoBar-hd-name,
.productInfoBar-hd-meta {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    flex-basis: 50%;
}

.productInfoBar-hd-meta {
    padding-left: 20px;
    text-align: right;
}

.productInfoBar-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 5px;
    font-size: 10px;
}
