.siteListing {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #656565;
}

.siteListing:hover,
.siteListing:focus,
.siteListing:active {
    background-color: #98f9ff;
}

.siteListing-link {
    display: block;
    flex-grow: 1;
    padding: 9px 9px 9px 15px;
    font-size: 14px;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
}

.siteListing:hover .siteListing-link,
.siteListing:focus .siteListing-link,
.siteListing:active .siteListing-link {
    color: #747474;
}

.siteListing-fav {
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
}
