$pricingContractTable_targetWidth: 616;
$pricingContractTable_pointHeader_targetWidth: 249;
$pricingContractTable_valueHeader_targetWidth: 364;

.pricingContractTable {
    .field-error {
        margin: 10px 0 0;
    }
}

.pricingContractTable--pointHeader {
    width: ( $pricingContractTable_pointHeader_targetWidth / $pricingContractTable_targetWidth ) * 100%;
}

.pricingContractTable--valueHeader {
    width: ( $pricingContractTable_valueHeader_targetWidth / $pricingContractTable_targetWidth ) * 100%;
}

.pricingContractTable--actions {
    display: flex;
}

.pricingContractTable--actions-value {
    flex: 1;
}

.pricingContractTable--actions-buttons {
    text-align: right;
}
