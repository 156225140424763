/*
    User Search Typeahead
*/

.userTypeahead {
    line-height: 1.25;
}

.userTypeahead strong {
    color: #4a90e2;
}

.typeahead-list-row_isActive .userTypeahead strong {
    color: white;
}

.userTypeahead-name {
    font-size: 14px;
}

.userTypeahead-username {
    font-style: italic;
}
