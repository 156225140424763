.quoted {
    font-style: italic;

    &:before {
        content: "\201C";
    }

    &:after {
        content: "\201D",;
    }
}
