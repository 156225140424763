// Lifted from Bootstrap's type.scss file
@include text-emphasis-variant(".text-primary", $brand-primary);
@include text-emphasis-variant(".text-success", $state-success-text);
@include text-emphasis-variant(".text-info", $state-info-text);
@include text-emphasis-variant(".text-warning", $state-warning-text);
@include text-emphasis-variant(".text-danger", $state-danger-text);

@include bg-variant(".bg-primary", $brand-primary);
@include bg-variant(".bg-success", $state-success-bg);
@include bg-variant(".bg-info", $state-info-bg);
@include bg-variant(".bg-warning", $state-warning-bg);
@include bg-variant(".bg-danger", $state-danger-bg);

// Additions
@include text-emphasis-variant(".text-pending", #ababab);
