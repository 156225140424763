.modal {
    transition: all 0.15s;
}

.modal-open .modal {
    backdrop-filter: blur(5px);
}

.modal-header {
    background-color: #505050;
    color: #ffffff;
    min-height: 16.42857143px;

    @debug "TODO: Remove contextual selection of h1 after headings have been cleaned.";

    h1 {
        margin-bottom: 0;
        color: #efefef;
    }

    h3 {
        margin-bottom: 0;
    }
}

.modal-body {
    background-color: #f6f7f9;

    @debug "TODO: Remove contextual selection. Put into place to carry over original CSS.";

    h3 {
        margin-bottom: (15 / 16) * 1em;
    }

    p {
        margin-bottom: (15 / 13) * 1em;
    }
}

.modal-backdrop.in {
    opacity: 0.15;
}
