.editing .view {
    display: none;
}

.editing .edit {
    display: block;
}

.edit {
    /* display: none; */
}

ul.included-object-list { list-style: none; }
ul.included-object-list li { padding: 10px; background-color: #eeeeee; margin: 4px 0; font-size: 14px; border-radius: 4px; }
ul.included-object-list li.empty { font-style: italic; color: #999999; }
.svg.remove-object-from-list { width: 15px; height: 15px; float: right; margin-top: 2px; cursor: pointer; fill: #cccccc; }
.svg.remove-object-from-list:hover { fill: #cc0000; }

/* below is some hack css so the press sheet page will display nicer */

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    color: #23abc3;
}

.caret.caret-reversed {
    border-top-width: 0;
    border-bottom: 5px solid #23abc3;
}

#cboxClose {
    top: 10px;
    right: 10px;
    z-index: 500;
}

.noty-wrapper {
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 9999999;
}

.noty_message {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 8px 10px;
    width: auto;
    position: relative;
    font-weight: bold;
}

.noty {
    overflow: hidden;
    padding: 20px 80px;
    margin: 0;
    background-attachment: scroll;
    background-color: rgb(0, 0, 0);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    box-shadow: rgba(0, 0, 0, 0.0980392) 0 -2px 4px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    background-position: 0% 0%, left, center;
    background-repeat: repeat-x, no-repeat;
    background-size: auto auto, 75px 75px;
}

.noty-warning {
    background-color: rgb(210, 50, 45);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAoCAYAAAAPOoFWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPZJREFUeNq81tsOgjAMANB2ov7/7ypaN7IlIwi9rGuT8QSc9EIDAsAznxvY4pXPKr05RUE5MEVB+TyWfCEl9LZApYopCmo9C4FKSMtYoI8Bwv79aQJU4l6hXXCZrQbokJEksxHo9KMOgc6w1atHXM8K9DVC7FQnJ0i8iK3QooGgbnyKgMDygBWyYFZoqx4qS27KqLZJjA1D0jK6QJcYEQEiWv9PGkTsbqxQ8oT+ZtZB6AkdsJnQDnMoHXHLGKOgDYuCWmYhEERCI5gaamW0bnHdA3k2ltlIN+2qKRyCND0bhqSYCyTB3CAOc4WusBEIpkeBuPgJMAAX8Hs1NfqHRgAAAABJRU5ErkJggg==);
}

.noty-success {
    background-color: rgb(71, 164, 71);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAoCAYAAAAPOoFWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPZJREFUeNq81tsOgjAMANB2ov7/7ypaN7IlIwi9rGuT8QSc9EIDAsAznxvY4pXPKr05RUE5MEVB+TyWfCEl9LZApYopCmo9C4FKSMtYoI8Bwv79aQJU4l6hXXCZrQbokJEksxHo9KMOgc6w1atHXM8K9DVC7FQnJ0i8iK3QooGgbnyKgMDygBWyYFZoqx4qS27KqLZJjA1D0jK6QJcYEQEiWv9PGkTsbqxQ8oT+ZtZB6AkdsJnQDnMoHXHLGKOgDYuCWmYhEERCI5gaamW0bnHdA3k2ltlIN+2qKRyCND0bhqSYCyTB3CAOc4WusBEIpkeBuPgJMAAX8Hs1NfqHRgAAAABJRU5ErkJggg==);
}

.noty-info {
    background-color: rgb(2, 107, 255);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAoCAYAAAAPOoFWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPZJREFUeNq81tsOgjAMANB2ov7/7ypaN7IlIwi9rGuT8QSc9EIDAsAznxvY4pXPKr05RUE5MEVB+TyWfCEl9LZApYopCmo9C4FKSMtYoI8Bwv79aQJU4l6hXXCZrQbokJEksxHo9KMOgc6w1atHXM8K9DVC7FQnJ0i8iK3QooGgbnyKgMDygBWyYFZoqx4qS27KqLZJjA1D0jK6QJcYEQEiWv9PGkTsbqxQ8oT+ZtZB6AkdsJnQDnMoHXHLGKOgDYuCWmYhEERCI5gaamW0bnHdA3k2ltlIN+2qKRyCND0bhqSYCyTB3CAOc4WusBEIpkeBuPgJMAAX8Hs1NfqHRgAAAABJRU5ErkJggg==),
        url("/web3/build/admin/images/alertWarning_big.png");
    background-size: initial, contain;
}

.full-modal .modal-content {
    border: none;
    border-radius: 0;
}

.full-modal .modal-dialog {
    width: 100%;
    margin: 0;
}
