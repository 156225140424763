$_treeViewSpacing: 8px;

.treeView {
    @include list-reset;

    & .treeView {
        margin-left: 15px;
        border-left: 1px solid #dddddd;
    }
}

.treeView_isEmpty {
    padding-bottom: $_treeViewSpacing;
}

.treeView-item {
    margin: 0;
    overflow: hidden; // clearfix

    &:first-child > .treeView-item-body {
        margin-top: $_treeViewSpacing;
    }
}

.treeView-item_placeholder {
    background-color: #e4e6e9;
}

.treeView-item-body {
    padding: 0 0 0 5px;
    background: #ffffff;
}
