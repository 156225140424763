// .page {}

// .page-hd {}

.page-hd-title {
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
    font-size: 38px;
    font-size: 3.8rem;
    line-height: (42 / 38); // targetLineHeightPx / fontSizePx = unitless line height value
}

.page-bd {
    padding-top: 20px;
}

// .page-ft {}
