.dateSign {
    position: relative;

    &:before,
    &:after {
        position: relative;
        content: "/";
        left: 33%;
    }

    &:after {
        left: 66%;
    }
}
