.btnPlain {
    appearance: none;
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.btnPlain:focus,
.btnPlain:active {
    outline: none;
}

.mix--btnPlain_default { color: #ababab; }
.mix--btnPlain_default:hover { color: #808080; }
.mix--btnPlain_inheritSize { font-size: inherit; }
