.uploadFileList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.uploadFileList > * {
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid #e4e6e9;
}

.queueItem {
    line-height: 16px;
}

.queueItem-name {
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

.queueItem-progress {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 10px;
    vertical-align: middle;
}

.queueItem-progress-total,
.queueItem-progress-amount {
    position: absolute;
    height: 100%;
    border-radius: 5px;
    top: 0;
    left: 0;
}

.queueItem-progress-total {
    width: 100%;
    border: 1px solid #87cd7c;
    background-color: #eafbd7;
}

.queueItem-progress-amount {
    background-color: #87cd7c;
    text-indent: -9999px;
}

.queueItem-status {
    display: inline-block;
    height: 16px;
    vertical-align: middle;
}

.queueItem-status-error,
.queueItem-status-complete {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-indent: -9999px;
}

.queueItem-status-complete {
    background: transparent url("/web3/build/admin/images/save.png") no-repeat 0 0;
}

.queueItem-status-error {
    background: transparent url("/web3/build/admin/images/exclamation.png") no-repeat 0 0;
}

.queueItem-status-waiting {
    font-size: 11px;
    font-style: italic;
    color: darkgray;
}

.moxie-shim {
    display: none;
}

.fileProgressContainer ~ .moxie-shim {
    display: block;
}

.fileProgressContainer.ng-hide ~ .moxie-shim {
    display: none;
}
