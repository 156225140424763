/* ============================================ */
/* This file has a mobile-to-desktop breakpoint */
/* ============================================ */
@media screen and (max-width: 400px) {
    @-ms-viewport {
        width: 320px;
    }
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.grid-container:before,
.grid-5:before,
.mobile-grid-5:before,
.grid-10:before,
.mobile-grid-10:before,
.grid-15:before,
.mobile-grid-15:before,
.grid-20:before,
.mobile-grid-20:before,
.grid-25:before,
.mobile-grid-25:before,
.grid-30:before,
.mobile-grid-30:before,
.grid-35:before,
.mobile-grid-35:before,
.grid-40:before,
.mobile-grid-40:before,
.grid-45:before,
.mobile-grid-45:before,
.grid-50:before,
.mobile-grid-50:before,
.grid-55:before,
.mobile-grid-55:before,
.grid-60:before,
.mobile-grid-60:before,
.grid-65:before,
.mobile-grid-65:before,
.grid-70:before,
.mobile-grid-70:before,
.grid-75:before,
.mobile-grid-75:before,
.grid-80:before,
.mobile-grid-80:before,
.grid-85:before,
.mobile-grid-85:before,
.grid-90:before,
.mobile-grid-90:before,
.grid-95:before,
.mobile-grid-95:before,
.grid-100:before,
.mobile-grid-100:before,
.grid-33:before,
.mobile-grid-33:before,
.grid-66:before,
.mobile-grid-66:before,
.clearfix:before,
.grid-container:after,
.grid-5:after,
.mobile-grid-5:after,
.grid-10:after,
.mobile-grid-10:after,
.grid-15:after,
.mobile-grid-15:after,
.grid-20:after,
.mobile-grid-20:after,
.grid-25:after,
.mobile-grid-25:after,
.grid-30:after,
.mobile-grid-30:after,
.grid-35:after,
.mobile-grid-35:after,
.grid-40:after,
.mobile-grid-40:after,
.grid-45:after,
.mobile-grid-45:after,
.grid-50:after,
.mobile-grid-50:after,
.grid-55:after,
.mobile-grid-55:after,
.grid-60:after,
.mobile-grid-60:after,
.grid-65:after,
.mobile-grid-65:after,
.grid-70:after,
.mobile-grid-70:after,
.grid-75:after,
.mobile-grid-75:after,
.grid-80:after,
.mobile-grid-80:after,
.grid-85:after,
.mobile-grid-85:after,
.grid-90:after,
.mobile-grid-90:after,
.grid-95:after,
.mobile-grid-95:after,
.grid-100:after,
.mobile-grid-100:after,
.grid-33:after,
.mobile-grid-33:after,
.grid-66:after,
.mobile-grid-66:after,
.clearfix:after {
    content: ".";
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
}

.grid-container:after,
.grid-5:after,
.mobile-grid-5:after,
.grid-10:after,
.mobile-grid-10:after,
.grid-15:after,
.mobile-grid-15:after,
.grid-20:after,
.mobile-grid-20:after,
.grid-25:after,
.mobile-grid-25:after,
.grid-30:after,
.mobile-grid-30:after,
.grid-35:after,
.mobile-grid-35:after,
.grid-40:after,
.mobile-grid-40:after,
.grid-45:after,
.mobile-grid-45:after,
.grid-50:after,
.mobile-grid-50:after,
.grid-55:after,
.mobile-grid-55:after,
.grid-60:after,
.mobile-grid-60:after,
.grid-65:after,
.mobile-grid-65:after,
.grid-70:after,
.mobile-grid-70:after,
.grid-75:after,
.mobile-grid-75:after,
.grid-80:after,
.mobile-grid-80:after,
.grid-85:after,
.mobile-grid-85:after,
.grid-90:after,
.mobile-grid-90:after,
.grid-95:after,
.mobile-grid-95:after,
.grid-100:after,
.mobile-grid-100:after,
.grid-33:after,
.mobile-grid-33:after,
.grid-66:after,
.mobile-grid-66:after,
.clearfix:after {
    clear: both;
}

.grid-container {
    /* margin-left: auto;
  margin-right: auto; */
    padding-left: 10px;
    padding-right: 10px;
}

.restricted-width {
    max-width: 1200px;
}

.centered {
    margin-left: auto;
    margin-right: auto;
}

.grid-5,
.mobile-grid-5,
.grid-10,
.mobile-grid-10,
.grid-15,
.mobile-grid-15,
.grid-20,
.mobile-grid-20,
.grid-25,
.mobile-grid-25,
.grid-30,
.mobile-grid-30,
.grid-35,
.mobile-grid-35,
.grid-40,
.mobile-grid-40,
.grid-45,
.mobile-grid-45,
.grid-50,
.mobile-grid-50,
.grid-55,
.mobile-grid-55,
.grid-60,
.mobile-grid-60,
.grid-65,
.mobile-grid-65,
.grid-70,
.mobile-grid-70,
.grid-75,
.mobile-grid-75,
.grid-80,
.mobile-grid-80,
.grid-85,
.mobile-grid-85,
.grid-90,
.mobile-grid-90,
.grid-95,
.mobile-grid-95,
.grid-100,
.mobile-grid-100,
.grid-33,
.mobile-grid-33,
.grid-66,
.mobile-grid-66 {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.grid-parent {
    padding-left: 0;
    padding-right: 0;
}

.grid-container-internal {
    margin-left: -10px;
    margin-right: -10px;
}

.grid-container-internal:before,
.grid-container-internal:after {
    content: " ";
    display: table;
}

.grid-container-internal:after {
    clear: both;
}

@media screen and (max-width: 767px) {
    .mobile-push-5,
    .mobile-pull-5,
    .mobile-push-10,
    .mobile-pull-10,
    .mobile-push-15,
    .mobile-pull-15,
    .mobile-push-20,
    .mobile-pull-20,
    .mobile-push-25,
    .mobile-pull-25,
    .mobile-push-30,
    .mobile-pull-30,
    .mobile-push-35,
    .mobile-pull-35,
    .mobile-push-40,
    .mobile-pull-40,
    .mobile-push-45,
    .mobile-pull-45,
    .mobile-push-50,
    .mobile-pull-50,
    .mobile-push-55,
    .mobile-pull-55,
    .mobile-push-60,
    .mobile-pull-60,
    .mobile-push-65,
    .mobile-pull-65,
    .mobile-push-70,
    .mobile-pull-70,
    .mobile-push-75,
    .mobile-pull-75,
    .mobile-push-80,
    .mobile-pull-80,
    .mobile-push-85,
    .mobile-pull-85,
    .mobile-push-90,
    .mobile-pull-90,
    .mobile-push-95,
    .mobile-pull-95,
    .mobile-push-33,
    .mobile-pull-33,
    .mobile-push-66,
    .mobile-pull-66 {
        position: relative;
    }

    .hide-on-mobile {
        display: none !important;
    }

    .mobile-grid-5 {
        float: left;
        width: 5%;
    }

    .mobile-prefix-5 {
        margin-left: 5%;
    }

    .mobile-suffix-5 {
        margin-right: 5%;
    }

    .mobile-push-5 {
        left: 5%;
    }

    .mobile-pull-5 {
        left: -5%;
    }

    .mobile-grid-10 {
        float: left;
        width: 10%;
    }

    .mobile-prefix-10 {
        margin-left: 10%;
    }

    .mobile-suffix-10 {
        margin-right: 10%;
    }

    .mobile-push-10 {
        left: 10%;
    }

    .mobile-pull-10 {
        left: -10%;
    }

    .mobile-grid-15 {
        float: left;
        width: 15%;
    }

    .mobile-prefix-15 {
        margin-left: 15%;
    }

    .mobile-suffix-15 {
        margin-right: 15%;
    }

    .mobile-push-15 {
        left: 15%;
    }

    .mobile-pull-15 {
        left: -15%;
    }

    .mobile-grid-20 {
        float: left;
        width: 20%;
    }

    .mobile-prefix-20 {
        margin-left: 20%;
    }

    .mobile-suffix-20 {
        margin-right: 20%;
    }

    .mobile-push-20 {
        left: 20%;
    }

    .mobile-pull-20 {
        left: -20%;
    }

    .mobile-grid-25 {
        float: left;
        width: 25%;
    }

    .mobile-prefix-25 {
        margin-left: 25%;
    }

    .mobile-suffix-25 {
        margin-right: 25%;
    }

    .mobile-push-25 {
        left: 25%;
    }

    .mobile-pull-25 {
        left: -25%;
    }

    .mobile-grid-30 {
        float: left;
        width: 30%;
    }

    .mobile-prefix-30 {
        margin-left: 30%;
    }

    .mobile-suffix-30 {
        margin-right: 30%;
    }

    .mobile-push-30 {
        left: 30%;
    }

    .mobile-pull-30 {
        left: -30%;
    }

    .mobile-grid-35 {
        float: left;
        width: 35%;
    }

    .mobile-prefix-35 {
        margin-left: 35%;
    }

    .mobile-suffix-35 {
        margin-right: 35%;
    }

    .mobile-push-35 {
        left: 35%;
    }

    .mobile-pull-35 {
        left: -35%;
    }

    .mobile-grid-40 {
        float: left;
        width: 40%;
    }

    .mobile-prefix-40 {
        margin-left: 40%;
    }

    .mobile-suffix-40 {
        margin-right: 40%;
    }

    .mobile-push-40 {
        left: 40%;
    }

    .mobile-pull-40 {
        left: -40%;
    }

    .mobile-grid-45 {
        float: left;
        width: 45%;
    }

    .mobile-prefix-45 {
        margin-left: 45%;
    }

    .mobile-suffix-45 {
        margin-right: 45%;
    }

    .mobile-push-45 {
        left: 45%;
    }

    .mobile-pull-45 {
        left: -45%;
    }

    .mobile-grid-50 {
        float: left;
        width: 50%;
    }

    .mobile-prefix-50 {
        margin-left: 50%;
    }

    .mobile-suffix-50 {
        margin-right: 50%;
    }

    .mobile-push-50 {
        left: 50%;
    }

    .mobile-pull-50 {
        left: -50%;
    }

    .mobile-grid-55 {
        float: left;
        width: 55%;
    }

    .mobile-prefix-55 {
        margin-left: 55%;
    }

    .mobile-suffix-55 {
        margin-right: 55%;
    }

    .mobile-push-55 {
        left: 55%;
    }

    .mobile-pull-55 {
        left: -55%;
    }

    .mobile-grid-60 {
        float: left;
        width: 60%;
    }

    .mobile-prefix-60 {
        margin-left: 60%;
    }

    .mobile-suffix-60 {
        margin-right: 60%;
    }

    .mobile-push-60 {
        left: 60%;
    }

    .mobile-pull-60 {
        left: -60%;
    }

    .mobile-grid-65 {
        float: left;
        width: 65%;
    }

    .mobile-prefix-65 {
        margin-left: 65%;
    }

    .mobile-suffix-65 {
        margin-right: 65%;
    }

    .mobile-push-65 {
        left: 65%;
    }

    .mobile-pull-65 {
        left: -65%;
    }

    .mobile-grid-70 {
        float: left;
        width: 70%;
    }

    .mobile-prefix-70 {
        margin-left: 70%;
    }

    .mobile-suffix-70 {
        margin-right: 70%;
    }

    .mobile-push-70 {
        left: 70%;
    }

    .mobile-pull-70 {
        left: -70%;
    }

    .mobile-grid-75 {
        float: left;
        width: 75%;
    }

    .mobile-prefix-75 {
        margin-left: 75%;
    }

    .mobile-suffix-75 {
        margin-right: 75%;
    }

    .mobile-push-75 {
        left: 75%;
    }

    .mobile-pull-75 {
        left: -75%;
    }

    .mobile-grid-80 {
        float: left;
        width: 80%;
    }

    .mobile-prefix-80 {
        margin-left: 80%;
    }

    .mobile-suffix-80 {
        margin-right: 80%;
    }

    .mobile-push-80 {
        left: 80%;
    }

    .mobile-pull-80 {
        left: -80%;
    }

    .mobile-grid-85 {
        float: left;
        width: 85%;
    }

    .mobile-prefix-85 {
        margin-left: 85%;
    }

    .mobile-suffix-85 {
        margin-right: 85%;
    }

    .mobile-push-85 {
        left: 85%;
    }

    .mobile-pull-85 {
        left: -85%;
    }

    .mobile-grid-90 {
        float: left;
        width: 90%;
    }

    .mobile-prefix-90 {
        margin-left: 90%;
    }

    .mobile-suffix-90 {
        margin-right: 90%;
    }

    .mobile-push-90 {
        left: 90%;
    }

    .mobile-pull-90 {
        left: -90%;
    }

    .mobile-grid-95 {
        float: left;
        width: 95%;
    }

    .mobile-prefix-95 {
        margin-left: 95%;
    }

    .mobile-suffix-95 {
        margin-right: 95%;
    }

    .mobile-push-95 {
        left: 95%;
    }

    .mobile-pull-95 {
        left: -95%;
    }

    .mobile-grid-33 {
        float: left;
        width: 33.33333%;
    }

    .mobile-prefix-33 {
        margin-left: 33.33333%;
    }

    .mobile-suffix-33 {
        margin-right: 33.33333%;
    }

    .mobile-push-33 {
        left: 33.33333%;
    }

    .mobile-pull-33 {
        left: -33.33333%;
    }

    .mobile-grid-66 {
        float: left;
        width: 66.66667%;
    }

    .mobile-prefix-66 {
        margin-left: 66.66667%;
    }

    .mobile-suffix-66 {
        margin-right: 66.66667%;
    }

    .mobile-push-66 {
        left: 66.66667%;
    }

    .mobile-pull-66 {
        left: -66.66667%;
    }

    .mobile-grid-100 {
        clear: both;
        width: 100%;
    }
}
@media screen and (min-width: 768px) {
    .push-5,
    .pull-5,
    .push-10,
    .pull-10,
    .push-15,
    .pull-15,
    .push-20,
    .pull-20,
    .push-25,
    .pull-25,
    .push-30,
    .pull-30,
    .push-35,
    .pull-35,
    .push-40,
    .pull-40,
    .push-45,
    .pull-45,
    .push-50,
    .pull-50,
    .push-55,
    .pull-55,
    .push-60,
    .pull-60,
    .push-65,
    .pull-65,
    .push-70,
    .pull-70,
    .push-75,
    .pull-75,
    .push-80,
    .pull-80,
    .push-85,
    .pull-85,
    .push-90,
    .pull-90,
    .push-95,
    .pull-95,
    .push-33,
    .pull-33,
    .push-66,
    .pull-66 {
        position: relative;
    }

    .hide-on-desktop {
        display: none !important;
    }

    .grid-5 {
        float: left;
        width: 5%;
    }

    .prefix-5 {
        margin-left: 5%;
    }

    .suffix-5 {
        margin-right: 5%;
    }

    .push-5 {
        left: 5%;
    }

    .pull-5 {
        left: -5%;
    }

    .grid-10 {
        float: left;
        width: 10%;
    }

    .prefix-10 {
        margin-left: 10%;
    }

    .suffix-10 {
        margin-right: 10%;
    }

    .push-10 {
        left: 10%;
    }

    .pull-10 {
        left: -10%;
    }

    .grid-15 {
        float: left;
        width: 15%;
    }

    .prefix-15 {
        margin-left: 15%;
    }

    .suffix-15 {
        margin-right: 15%;
    }

    .push-15 {
        left: 15%;
    }

    .pull-15 {
        left: -15%;
    }

    .grid-20 {
        float: left;
        width: 20%;
    }

    .prefix-20 {
        margin-left: 20%;
    }

    .suffix-20 {
        margin-right: 20%;
    }

    .push-20 {
        left: 20%;
    }

    .pull-20 {
        left: -20%;
    }

    .grid-25 {
        float: left;
        width: 25%;
    }

    .prefix-25 {
        margin-left: 25%;
    }

    .suffix-25 {
        margin-right: 25%;
    }

    .push-25 {
        left: 25%;
    }

    .pull-25 {
        left: -25%;
    }

    .grid-30 {
        float: left;
        width: 30%;
    }

    .prefix-30 {
        margin-left: 30%;
    }

    .suffix-30 {
        margin-right: 30%;
    }

    .push-30 {
        left: 30%;
    }

    .pull-30 {
        left: -30%;
    }

    .grid-35 {
        float: left;
        width: 35%;
    }

    .prefix-35 {
        margin-left: 35%;
    }

    .suffix-35 {
        margin-right: 35%;
    }

    .push-35 {
        left: 35%;
    }

    .pull-35 {
        left: -35%;
    }

    .grid-40 {
        float: left;
        width: 40%;
    }

    .prefix-40 {
        margin-left: 40%;
    }

    .suffix-40 {
        margin-right: 40%;
    }

    .push-40 {
        left: 40%;
    }

    .pull-40 {
        left: -40%;
    }

    .grid-45 {
        float: left;
        width: 45%;
    }

    .prefix-45 {
        margin-left: 45%;
    }

    .suffix-45 {
        margin-right: 45%;
    }

    .push-45 {
        left: 45%;
    }

    .pull-45 {
        left: -45%;
    }

    .grid-50 {
        float: left;
        width: 50%;
    }

    .prefix-50 {
        margin-left: 50%;
    }

    .suffix-50 {
        margin-right: 50%;
    }

    .push-50 {
        left: 50%;
    }

    .pull-50 {
        left: -50%;
    }

    .grid-55 {
        float: left;
        width: 55%;
    }

    .prefix-55 {
        margin-left: 55%;
    }

    .suffix-55 {
        margin-right: 55%;
    }

    .push-55 {
        left: 55%;
    }

    .pull-55 {
        left: -55%;
    }

    .grid-60 {
        float: left;
        width: 60%;
    }

    .prefix-60 {
        margin-left: 60%;
    }

    .suffix-60 {
        margin-right: 60%;
    }

    .push-60 {
        left: 60%;
    }

    .pull-60 {
        left: -60%;
    }

    .grid-65 {
        float: left;
        width: 65%;
    }

    .prefix-65 {
        margin-left: 65%;
    }

    .suffix-65 {
        margin-right: 65%;
    }

    .push-65 {
        left: 65%;
    }

    .pull-65 {
        left: -65%;
    }

    .grid-70 {
        float: left;
        width: 70%;
    }

    .prefix-70 {
        margin-left: 70%;
    }

    .suffix-70 {
        margin-right: 70%;
    }

    .push-70 {
        left: 70%;
    }

    .pull-70 {
        left: -70%;
    }

    .grid-75 {
        float: left;
        width: 75%;
    }

    .prefix-75 {
        margin-left: 75%;
    }

    .suffix-75 {
        margin-right: 75%;
    }

    .push-75 {
        left: 75%;
    }

    .pull-75 {
        left: -75%;
    }

    .grid-80 {
        float: left;
        width: 80%;
    }

    .prefix-80 {
        margin-left: 80%;
    }

    .suffix-80 {
        margin-right: 80%;
    }

    .push-80 {
        left: 80%;
    }

    .pull-80 {
        left: -80%;
    }

    .grid-85 {
        float: left;
        width: 85%;
    }

    .prefix-85 {
        margin-left: 85%;
    }

    .suffix-85 {
        margin-right: 85%;
    }

    .push-85 {
        left: 85%;
    }

    .pull-85 {
        left: -85%;
    }

    .grid-90 {
        float: left;
        width: 90%;
    }

    .prefix-90 {
        margin-left: 90%;
    }

    .suffix-90 {
        margin-right: 90%;
    }

    .push-90 {
        left: 90%;
    }

    .pull-90 {
        left: -90%;
    }

    .grid-95 {
        float: left;
        width: 95%;
    }

    .prefix-95 {
        margin-left: 95%;
    }

    .suffix-95 {
        margin-right: 95%;
    }

    .push-95 {
        left: 95%;
    }

    .pull-95 {
        left: -95%;
    }

    .grid-33 {
        float: left;
        width: 33.33333%;
    }

    .prefix-33 {
        margin-left: 33.33333%;
    }

    .suffix-33 {
        margin-right: 33.33333%;
    }

    .push-33 {
        left: 33.33333%;
    }

    .pull-33 {
        left: -33.33333%;
    }

    .grid-66 {
        float: left;
        width: 66.66667%;
    }

    .prefix-66 {
        margin-left: 66.66667%;
    }

    .suffix-66 {
        margin-right: 66.66667%;
    }

    .push-66 {
        left: 66.66667%;
    }

    .pull-66 {
        left: -66.66667%;
    }

    .grid-100 {
        clear: both;
        width: 100%;
    }
}
