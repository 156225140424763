$inputToggleWidth: 36px;
$switchBorderWidth: 2px;

$inputToggleWidth_sm: 24px;
$switchBorderWidth_sm: 1px;

$inputToggleWidth_lg: 54px;
$switchBorderWidth_lg: 3px;

@mixin inputToggleVariant ($width, $borderWidth) {
    $switchSize: $width / 2;

    width: $width;
    height: $width / 2;
    margin: $borderWidth ($borderWidth * 2);
    border-radius: $width / 4;

    .inputToggle-switch {
        width: $switchSize;
        height: $switchSize;
        border: $borderWidth solid silver;
        top: -$borderWidth;
        left: -$borderWidth;
        box-sizing: content-box;
    }

    &.inputToggle_on {
        .inputToggle-switch {
            left: $switchSize - $borderWidth;
        }
    }
}

.inputToggleWrapper {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &[disabled] {
        opacity: 0.75;
        cursor: not-allowed;
    }
}

.inputToggleWrapper-label {
    display: inline-block;
    margin-left: 3px;
}

.inputToggle {
    @include inputToggleVariant($inputToggleWidth, $switchBorderWidth);

    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: silver;
    box-shadow: inset 0 1px 2px 2px rgba(0, 0, 0, 0.2);

    &.theme--inputToggle_retro {
        background-color: #d67e7e;
    }

    .inputToggle-switch {
        border-radius: 50%;
        position: absolute;
        background-color: gray;
        transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
        transition-property: left, box-shadow;
        box-shadow: 2px 0 2px rgba(0, 0, 0, 0.2);
    }

    &.inputToggle_on {
        background-color: rgb(35, 117, 240);
        box-shadow: inset 0 1px 2px 2px rgba(0, 0, 0, 0);

        .inputToggle-switch {
            box-shadow: -2px 0 2px rgba(0, 0, 0, 0.2);
            background-color: white;
        }

        &.theme--inputToggle_retro {
            background-color: #9bbd9b; // #5a8a5a
        }
    }
}

.inputToggle_sm {
    @include inputToggleVariant($inputToggleWidth_sm, $switchBorderWidth_sm);
}

.inputToggle_lg {
    @include inputToggleVariant($inputToggleWidth_lg, $switchBorderWidth_lg);
}
