@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.animate-spin {
    animation: spin 2s infinite linear;
    display: inline-block;
}
