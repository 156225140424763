.sortableGroup {
    list-style: none;

    > li {
        margin: 5px 0;
        padding: 0;
        cursor: pointer;
        cursor: -webkit-grab;
        transform: translateX(0);
        transition: transform 0.5s;

        &:hover {
            transform: translateX(5px);
        }

        &:active {
            cursor: pointer;
            cursor: -webkit-grabbing;
        }

        &.ng-leave,
        &.ng-hide-add-active,
        &.ng-leave-active {
            display: none !important;
        }
    }

    > .sortableGroup-item_withActionItem {
        transform: none;
        transition: none;

        &:hover {
            transform: none;
        }

        .actionableItem-item {
            transform: translateX(0);
            transition: transform 0.5s;

            &:hover {
                transform: translateX(5px);
            }
        }
    }
}

.sortableGroup_cloneSource {
    box-sizing: border-box;
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden; // prevent scroll bar from showing due to the item transform on hover
    padding-bottom: 25px; // To account for .maskedScrollWrapper

    > .ui-sortable-placeholder {
        display: none;
    }
}

.sortableGroup_cloneDestination {
    > .ui-sortable-placeholder {
        display: list-item;
        width: auto !important;
        visibility: visible !important;
        border: 1px dashed;
        border-radius: 5px;
        opacity: 0.5;
    }

    > .sortableGroup_cloneDestination-emptyItem {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding: 30px;
        border-radius: 10px;
        border: 4px dashed rgba(95, 95, 95, 0.6);
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &:hover {
            transform: none;
        }
    }

    > .sortableGroup_cloneDestination-emptyItem + .ui-sortable-placeholder {
        display: none;
    }

    &.sortableGroup_isDropPending {
        > .sortableGroup_cloneDestination-emptyItem {
            color: #5cb85c;
            border-color: #5cb85c;
            transform-origin: center center;
            transform: scale(1.05);
        }
    }
}

