.messageBar {
    clear: both;
    padding: 5px 10px;
    background: #ababab;
    color: #787878;
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial, sans-serif;
}

.messageBar_warning {
    background: $brand-warning;
    color: $state-warning-border;
}
