.hList {
    @include list-reset;

    margin: -5px;
    display: flex;
    flex-wrap: wrap;
}

.hList > * {
    margin: 5px;
    padding: 0;
}

.hList_piped > * + * {
    padding-left: 5px;
    border-left: 1px solid;
}

.mix-hList_centered {
    align-items: center;
}
