.bulkActions {
    @include clearfix;
    position: relative;
    display: inline-block;
}

.bulkActions-trigger {
    float: left;
    transition: transform 1s;
}

.bulkActions-trigger:hover,
.bulkActions-trigger_isOpen {
    transform: rotate(90deg);
}
