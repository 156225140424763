.cells {
    display: flex;
    flex-direction: row;
}

.cells > * {
    flex-grow: 1;
}

.cells > * + * {
    margin-left: 15px;
}

.cells-cell_fixed {
    flex-grow: 0;
}

.mix--cells-cell_centered {
    align-self: center;
}

.mix-cells_stripe:nth-child(odd) {
    background: rgba(0, 0, 0, 0.02);
}
