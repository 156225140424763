.typeahead {
    position: relative;
    clear: both; /* fixes issues when used inside a bootstrap input-group */
}

.typeahead-list {
    @include list-reset;

    border-color: #cccccc;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    max-height: 300px;
    overflow-y: auto;
}

.typeahead-list-row {
    padding: 5px;
    color: #444444;
    clear: both;
    margin: 0;
}

.typeahead-list-row strong {
    // font-weight: bold;
    // color: #000000;
}

.typeahead-list-row + .typeahead-list-row {
    margin-top: 5px;
}

.typeahead-list-row_isActive {
    background-color: #4a90e2;
    color: #ffffff;
}

.typeahead_segmented .typeahead-list-row + .typeahead-list-row {
    border-top: 1px solid #cccccc;
    margin-top: 0;
}

.angucomplete-searching {
    color: #acacac;
    font-size: 14px;
    padding: 5px;
}

.angucomplete-description {
    font-size: 14px;
}


