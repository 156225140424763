.bucketlist,
.bucketlist * {
    box-sizing: border-box;
}

.bucketlist {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}

.bucketlist-panel {
    flex: 6 6 46.01226993865031%;
}

.bucketlist-panel_controls {
    flex: 1 1 7.975460122699386%;
    padding: 0 1.9938650306748466%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    button {
        display: block;
        width: 100%;
        height: 26px;
        line-height: 0;
        padding: 5px;
        border: 1px solid #b2b3b6;
        border-radius: 6px;
        font-size: 16px;
        text-align: center;
        color: #888888;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
        background: #f5f5f6;
        background: -moz-linear-gradient(top, #f5f5f6 0%, #e7e9eb 100%);
        background: -webkit-linear-gradient(top, #f5f5f6 0%, #e7e9eb 100%);
        background: linear-gradient(to bottom, #f5f5f6 0%, #e7e9eb 100%);
        filter: progid:dximagetransform.microsoft.gradient(startColorstr="#f5f5f6", endColorstr="#e7e9eb", GradientType=0);
        cursor: pointer;
    }

    button:focus {
        outline: 0;
    }

    button:active {
        background: #cccccc;
    }

    button + button {
        margin-top: 10px;
    }
}

/* raising specificity to battle ".modal h3" - TODO: lower specificity once that has been addressed */
.bucketlist .bucketlist-panel-hd {
    margin: 0 0 0 3px;
    font-size: 13px;
    color: #9c9ca2;
}

.bucketlist-panel-bd {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 11px;
    padding: 0;
    margin: 0 0 2px;
    background-color: #ffffff;
    line-height: normal;
    border: 1px solid #dfe1e4;
    border-bottom-color: #b2b3b6;
    border-radius: 4px;
}

.bucketlist-panel-bd-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: #f6f7f9;
    border-bottom: 1px solid #dfe1e4;
    padding: 2px;

    input[type="text"] {
        flex: 1;
        padding: 8px;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 11px;
        vertical-align: baseline;
        font-family: "Proxima Nova Soft W03 Reg", Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif;
        background: #ffffff;
    }

    label {
        white-space: nowrap;
        margin: 0 5px;
    }
}

.bucketlist-panel-bd-content {
    height: 200px;
    overflow-y: auto;
}

.bucketlist-panel-bd-content-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;

    > li {
        cursor: pointer;
        padding: 2px 4px;
        margin: 0;
    }

    > li + li {
        margin-top: 2px;
    }

    > li.isActive {
        color: #ffffff;
        background-color: #314a66;
    }
}
