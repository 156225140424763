.listBordered {
    @include list-reset;
}

.listBordered > * {
    margin: 0;
    padding: 0;
}

.listBordered > * + * {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 15px;
}
