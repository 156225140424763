$linkColor: #506986;
$blockBtn_color: $linkColor;
$blockBtn_color_active: #666666;

// Use on any typical markup... anchors, buttons, divs, spans...

.blockBtn {
    appearance: none;
    background: transparent;
    border: 0;
    padding: 5px 30px;
    margin: 0;
    cursor: pointer;
    height: auto;
    width: auto;
    text-decoration: none;
    display: inline-block;
    color: $blockBtn_color;
    font-family: "Proxima Nova Soft W03 Reg", Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif;
    font-size: 14px;
    line-height: 21px;
    border-bottom: 3px solid transparent;

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        border-bottom: 3px solid $blockBtn_color;
    }
}

.blockBtn_active,
.blockBtn_active:hover {
    color: $blockBtn_color_active;
    border-bottom: 3px solid $blockBtn_color_active;
}
