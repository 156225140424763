.iList {
    @include list-reset;
}

.iList > * {
    display: inline-block;
    vertical-align: middle;
}

.iList_piped > * + *:before {
    content: "\00a0|\00a0\00a0";
}
