.quoteStatus {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    padding: 2px 10px 2px 8px;
    border-left: 3px solid;
    color: $state-info-text;
    background: $state-info-bg;
}

// .mix-quoteStatus_NEW_UNSAVED { color: $gray-light; }
// .mix-quoteStatus_NEW { color: $brand-primary; }
// .mix-quoteStatus_ESTIMATE_REQUESTED { color: $brand-warning; }
// .mix-quoteStatus_ESTIMATE_IN_PROGRESS { color: $state-info-text; }
// .mix-quoteStatus_ESTIMATE_COMPLETE { color: $brand-success; }
// .mix-quoteStatus_SUBMITTED_TO_CUSTOMER { color: $state-info-text; }
// .mix-quoteStatus_REQUOTE_REQUESTED { color: $state-warning-text; }
// .mix-quoteStatus_ORDERED { color: $brand-info; }
// .mix-quoteStatus_DELETED { color: $brand-danger; }
// .mix-quoteStatus_CLOSED { color: $gray-lighter; }
// .mix-quoteStatus_DECLINED { color: $state-danger-text; }
