body {
    background: none;
    margin-right: -10px;
}
.page-masthead h1 { font-size: 28px; }

.subNavContainer,
#findOrder,
#footer,
.noPrint,
.dashboardGroup,
.dashboardSubMenu,
.quickSearchContainer,
#site-nav,
#supplier-menu,
header,
#batch-viewing-options,
.back-to-list {
    display: none;
}
.site-scope #main { margin-left: 0; }

table.ledger-batch td {
    font-size: 11px;
}

.list-table tr th {
    white-space: normal;
}

body { background: none; }

h1,
h2,
h3 { text-shadow: none; }

.resultsList td,
.resultsList th {
    background: none;
    border-bottom: 1px solid #dfe1e4;
}

.resultsList th {
    background: #eeeeee url(/web3/build/admin/images/grayGradient.png);
}

.resultsList td {
    color: #111111;
}
