.mdColor {
    color: rgba(255, 255, 255, 0.87);
}

.mix--mdColor_dark {
    color: rgba(0, 0, 0, 0.87);
}

.mix--mdColor_lightStrong {
    color: #ffffff;
}

.mdColor_black {
    background-color: #000000;
}

.mdColor_white {
    background-color: #ffffff;
}

.mdColor_red50 {
    background-color: #ffebee;
}

.mdColor_red100 {
    background-color: #ffcdd2;
}

.mdColor_red200 {
    background-color: #ef9a9a;
}

.mdColor_red300 {
    background-color: #e57373;
}

.mdColor_red400 {
    background-color: #ef5350;
}

.mdColor_red500 {
    background-color: #f44336;
}

.mdColor_red600 {
    background-color: #e53935;
}

.mdColor_red700 {
    background-color: #d32f2f;
}

.mdColor_red800 {
    background-color: #c62828;
}

.mdColor_red900 {
    background-color: #b71c1c;
}

.mdColor_redA100 {
    background-color: #ff8a80;
}

.mdColor_redA200 {
    background-color: #ff5252;
}

.mdColor_redA400 {
    background-color: #ff1744;
}

.mdColor_redA700 {
    background-color: #d50000;
}

.mdColor_pink50 {
    background-color: #fce4ec;
}

.mdColor_pink100 {
    background-color: #f8bbd0;
}

.mdColor_pink200 {
    background-color: #f48fb1;
}

.mdColor_pink300 {
    background-color: #f06292;
}

.mdColor_pink400 {
    background-color: #ec407a;
}

.mdColor_pink500 {
    background-color: #e91e63;
}

.mdColor_pink600 {
    background-color: #d81b60;
}

.mdColor_pink700 {
    background-color: #c2185b;
}

.mdColor_pink800 {
    background-color: #ad1457;
}

.mdColor_pink900 {
    background-color: #880e4f;
}

.mdColor_pinkA100 {
    background-color: #ff80ab;
}

.mdColor_pinkA200 {
    background-color: #ff4081;
}

.mdColor_pinkA400 {
    background-color: #f50057;
}

.mdColor_pinkA700 {
    background-color: #c51162;
}

.mdColor_purple50 {
    background-color: #f3e5f5;
}

.mdColor_purple100 {
    background-color: #e1bee7;
}

.mdColor_purple200 {
    background-color: #ce93d8;
}

.mdColor_purple300 {
    background-color: #ba68c8;
}

.mdColor_purple400 {
    background-color: #ab47bc;
}

.mdColor_purple500 {
    background-color: #9c27b0;
}

.mdColor_purple600 {
    background-color: #8e24aa;
}

.mdColor_purple700 {
    background-color: #7b1fa2;
}

.mdColor_purple800 {
    background-color: #6a1b9a;
}

.mdColor_purple900 {
    background-color: #4a148c;
}

.mdColor_purpleA100 {
    background-color: #ea80fc;
}

.mdColor_purpleA200 {
    background-color: #e040fb;
}

.mdColor_purpleA400 {
    background-color: #d500f9;
}

.mdColor_purpleA700 {
    background-color: #aa00ff;
}

.mdColor_deepPurple50 {
    background-color: #ede7f6;
}

.mdColor_deepPurple100 {
    background-color: #d1c4e9;
}

.mdColor_deepPurple200 {
    background-color: #b39ddb;
}

.mdColor_deepPurple300 {
    background-color: #9575cd;
}

.mdColor_deepPurple400 {
    background-color: #7e57c2;
}

.mdColor_deepPurple500 {
    background-color: #673ab7;
}

.mdColor_deepPurple600 {
    background-color: #5e35b1;
}

.mdColor_deepPurple700 {
    background-color: #512da8;
}

.mdColor_deepPurple800 {
    background-color: #4527a0;
}

.mdColor_deepPurple900 {
    background-color: #311b92;
}

.mdColor_deepPurpleA100 {
    background-color: #b388ff;
}

.mdColor_deepPurpleA200 {
    background-color: #7c4dff;
}

.mdColor_deepPurpleA400 {
    background-color: #651fff;
}

.mdColor_deepPurpleA700 {
    background-color: #6200ea;
}

.mdColor_indigo50 {
    background-color: #e8eaf6;
}

.mdColor_indigo100 {
    background-color: #c5cae9;
}

.mdColor_indigo200 {
    background-color: #9fa8da;
}

.mdColor_indigo300 {
    background-color: #7986cb;
}

.mdColor_indigo400 {
    background-color: #5c6bc0;
}

.mdColor_indigo500 {
    background-color: #3f51b5;
}

.mdColor_indigo600 {
    background-color: #3949ab;
}

.mdColor_indigo700 {
    background-color: #303f9f;
}

.mdColor_indigo800 {
    background-color: #283593;
}

.mdColor_indigo900 {
    background-color: #1a237e;
}

.mdColor_indigoA100 {
    background-color: #8c9eff;
}

.mdColor_indigoA200 {
    background-color: #536dfe;
}

.mdColor_indigoA400 {
    background-color: #3d5afe;
}

.mdColor_indigoA700 {
    background-color: #304ffe;
}

.mdColor_blue50 {
    background-color: #e3f2fd;
}

.mdColor_blue100 {
    background-color: #bbdefb;
}

.mdColor_blue200 {
    background-color: #90caf9;
}

.mdColor_blue300 {
    background-color: #64b5f6;
}

.mdColor_blue400 {
    background-color: #42a5f5;
}

.mdColor_blue500 {
    background-color: #2196f3;
}

.mdColor_blue600 {
    background-color: #1e88e5;
}

.mdColor_blue700 {
    background-color: #1976d2;
}

.mdColor_blue800 {
    background-color: #1565c0;
}

.mdColor_blue900 {
    background-color: #0d47a1;
}

.mdColor_blueA100 {
    background-color: #82b1ff;
}

.mdColor_blueA200 {
    background-color: #448aff;
}

.mdColor_blueA400 {
    background-color: #2979ff;
}

.mdColor_blueA700 {
    background-color: #2962ff;
}

.mdColor_lightBlue50 {
    background-color: #e1f5fe;
}

.mdColor_lightBlue100 {
    background-color: #b3e5fc;
}

.mdColor_lightBlue200 {
    background-color: #81d4fa;
}

.mdColor_lightBlue300 {
    background-color: #4fc3f7;
}

.mdColor_lightBlue400 {
    background-color: #29b6f6;
}

.mdColor_lightBlue500 {
    background-color: #03a9f4;
}

.mdColor_lightBlue600 {
    background-color: #039be5;
}

.mdColor_lightBlue700 {
    background-color: #0288d1;
}

.mdColor_lightBlue800 {
    background-color: #0277bd;
}

.mdColor_lightBlue900 {
    background-color: #01579b;
}

.mdColor_lightBlueA100 {
    background-color: #80d8ff;
}

.mdColor_lightBlueA200 {
    background-color: #40c4ff;
}

.mdColor_lightBlueA400 {
    background-color: #00b0ff;
}

.mdColor_lightBlueA700 {
    background-color: #0091ea;
}

.mdColor_cyan50 {
    background-color: #e0f7fa;
}

.mdColor_cyan100 {
    background-color: #b2ebf2;
}

.mdColor_cyan200 {
    background-color: #80deea;
}

.mdColor_cyan300 {
    background-color: #4dd0e1;
}

.mdColor_cyan400 {
    background-color: #26c6da;
}

.mdColor_cyan500 {
    background-color: #00bcd4;
}

.mdColor_cyan600 {
    background-color: #00acc1;
}

.mdColor_cyan700 {
    background-color: #0097a7;
}

.mdColor_cyan800 {
    background-color: #00838f;
}

.mdColor_cyan900 {
    background-color: #006064;
}

.mdColor_cyanA100 {
    background-color: #84ffff;
}

.mdColor_cyanA200 {
    background-color: #18ffff;
}

.mdColor_cyanA400 {
    background-color: #00e5ff;
}

.mdColor_cyanA700 {
    background-color: #00b8d4;
}

.mdColor_teal50 {
    background-color: #e0f2f1;
}

.mdColor_teal100 {
    background-color: #b2dfdb;
}

.mdColor_teal200 {
    background-color: #80cbc4;
}

.mdColor_teal300 {
    background-color: #4db6ac;
}

.mdColor_teal400 {
    background-color: #26a69a;
}

.mdColor_teal500 {
    background-color: #009688;
}

.mdColor_teal600 {
    background-color: #00897b;
}

.mdColor_teal700 {
    background-color: #00796b;
}

.mdColor_teal800 {
    background-color: #00695c;
}

.mdColor_teal900 {
    background-color: #004d40;
}

.mdColor_tealA100 {
    background-color: #a7ffeb;
}

.mdColor_tealA200 {
    background-color: #64ffda;
}

.mdColor_tealA400 {
    background-color: #1de9b6;
}

.mdColor_tealA700 {
    background-color: #00bfa5;
}

.mdColor_green50 {
    background-color: #e8f5e9;
}

.mdColor_green100 {
    background-color: #c8e6c9;
}

.mdColor_green200 {
    background-color: #a5d6a7;
}

.mdColor_green300 {
    background-color: #81c784;
}

.mdColor_green400 {
    background-color: #66bb6a;
}

.mdColor_green500 {
    background-color: #4caf50;
}

.mdColor_green600 {
    background-color: #43a047;
}

.mdColor_green700 {
    background-color: #388e3c;
}

.mdColor_green800 {
    background-color: #2e7d32;
}

.mdColor_green900 {
    background-color: #1b5e20;
}

.mdColor_greenA100 {
    background-color: #b9f6ca;
}

.mdColor_greenA200 {
    background-color: #69f0ae;
}

.mdColor_greenA400 {
    background-color: #00e676;
}

.mdColor_greenA700 {
    background-color: #00c853;
}

.mdColor_lightGreen50 {
    background-color: #f1f8e9;
}

.mdColor_lightGreen100 {
    background-color: #dcedc8;
}

.mdColor_lightGreen200 {
    background-color: #c5e1a5;
}

.mdColor_lightGreen300 {
    background-color: #aed581;
}

.mdColor_lightGreen400 {
    background-color: #9ccc65;
}

.mdColor_lightGreen500 {
    background-color: #8bc34a;
}

.mdColor_lightGreen600 {
    background-color: #7cb342;
}

.mdColor_lightGreen700 {
    background-color: #689f38;
}

.mdColor_lightGreen800 {
    background-color: #558b2f;
}

.mdColor_lightGreen900 {
    background-color: #33691e;
}

.mdColor_lightGreenA100 {
    background-color: #ccff90;
}

.mdColor_lightGreenA200 {
    background-color: #b2ff59;
}

.mdColor_lightGreenA400 {
    background-color: #76ff03;
}

.mdColor_lightGreenA700 {
    background-color: #64dd17;
}

.mdColor_lime50 {
    background-color: #f9fbe7;
}

.mdColor_lime100 {
    background-color: #f0f4c3;
}

.mdColor_lime200 {
    background-color: #e6ee9c;
}

.mdColor_lime300 {
    background-color: #dce775;
}

.mdColor_lime400 {
    background-color: #d4e157;
}

.mdColor_lime500 {
    background-color: #cddc39;
}

.mdColor_lime600 {
    background-color: #c0ca33;
}

.mdColor_lime700 {
    background-color: #afb42b;
}

.mdColor_lime800 {
    background-color: #9e9d24;
}

.mdColor_lime900 {
    background-color: #827717;
}

.mdColor_limeA100 {
    background-color: #f4ff81;
}

.mdColor_limeA200 {
    background-color: #eeff41;
}

.mdColor_limeA400 {
    background-color: #c6ff00;
}

.mdColor_limeA700 {
    background-color: #aeea00;
}

.mdColor_yellow50 {
    background-color: #fffde7;
}

.mdColor_yellow100 {
    background-color: #fff9c4;
}

.mdColor_yellow200 {
    background-color: #fff59d;
}

.mdColor_yellow300 {
    background-color: #fff176;
}

.mdColor_yellow400 {
    background-color: #ffee58;
}

.mdColor_yellow500 {
    background-color: #ffeb3b;
}

.mdColor_yellow600 {
    background-color: #fdd835;
}

.mdColor_yellow700 {
    background-color: #fbc02d;
}

.mdColor_yellow800 {
    background-color: #f9a825;
}

.mdColor_yellow900 {
    background-color: #f57f17;
}

.mdColor_yellowA100 {
    background-color: #ffff8d;
}

.mdColor_yellowA200 {
    background-color: #ffff00;
}

.mdColor_yellowA400 {
    background-color: #ffea00;
}

.mdColor_yellowA700 {
    background-color: #ffd600;
}

.mdColor_amber50 {
    background-color: #fff8e1;
}

.mdColor_amber100 {
    background-color: #ffecb3;
}

.mdColor_amber200 {
    background-color: #ffe082;
}

.mdColor_amber300 {
    background-color: #ffd54f;
}

.mdColor_amber400 {
    background-color: #ffca28;
}

.mdColor_amber500 {
    background-color: #ffc107;
}

.mdColor_amber600 {
    background-color: #ffb300;
}

.mdColor_amber700 {
    background-color: #ffa000;
}

.mdColor_amber800 {
    background-color: #ff8f00;
}

.mdColor_amber900 {
    background-color: #ff6f00;
}

.mdColor_amberA100 {
    background-color: #ffe57f;
}

.mdColor_amberA200 {
    background-color: #ffd740;
}

.mdColor_amberA400 {
    background-color: #ffc400;
}

.mdColor_amberA700 {
    background-color: #ffab00;
}

.mdColor_orange50 {
    background-color: #fff3e0;
}

.mdColor_orange100 {
    background-color: #ffe0b2;
}

.mdColor_orange200 {
    background-color: #ffcc80;
}

.mdColor_orange300 {
    background-color: #ffb74d;
}

.mdColor_orange400 {
    background-color: #ffa726;
}

.mdColor_orange500 {
    background-color: #ff9800;
}

.mdColor_orange600 {
    background-color: #fb8c00;
}

.mdColor_orange700 {
    background-color: #f57c00;
}

.mdColor_orange800 {
    background-color: #ef6c00;
}

.mdColor_orange900 {
    background-color: #e65100;
}

.mdColor_orangeA100 {
    background-color: #ffd180;
}

.mdColor_orangeA200 {
    background-color: #ffab40;
}

.mdColor_orangeA400 {
    background-color: #ff9100;
}

.mdColor_orangeA700 {
    background-color: #ff6d00;
}

.mdColor_deepOrange50 {
    background-color: #fbe9e7;
}

.mdColor_deepOrange100 {
    background-color: #ffccbc;
}

.mdColor_deepOrange200 {
    background-color: #ffab91;
}

.mdColor_deepOrange300 {
    background-color: #ff8a65;
}

.mdColor_deepOrange400 {
    background-color: #ff7043;
}

.mdColor_deepOrange500 {
    background-color: #ff5722;
}

.mdColor_deepOrange600 {
    background-color: #f4511e;
}

.mdColor_deepOrange700 {
    background-color: #e64a19;
}

.mdColor_deepOrange800 {
    background-color: #d84315;
}

.mdColor_deepOrange900 {
    background-color: #bf360c;
}

.mdColor_deepOrangeA100 {
    background-color: #ff9e80;
}

.mdColor_deepOrangeA200 {
    background-color: #ff6e40;
}

.mdColor_deepOrangeA400 {
    background-color: #ff3d00;
}

.mdColor_deepOrangeA700 {
    background-color: #dd2c00;
}

.mdColor_brown50 {
    background-color: #efebe9;
}

.mdColor_brown100 {
    background-color: #d7ccc8;
}

.mdColor_brown200 {
    background-color: #bcaaa4;
}

.mdColor_brown300 {
    background-color: #a1887f;
}

.mdColor_brown400 {
    background-color: #8d6e63;
}

.mdColor_brown500 {
    background-color: #795548;
}

.mdColor_brown600 {
    background-color: #6d4c41;
}

.mdColor_brown700 {
    background-color: #5d4037;
}

.mdColor_brown800 {
    background-color: #4e342e;
}

.mdColor_brown900 {
    background-color: #3e2723;
}

.mdColor_brownA100 {
    background-color: #d7ccc8;
}

.mdColor_brownA200 {
    background-color: #bcaaa4;
}

.mdColor_brownA400 {
    background-color: #8d6e63;
}

.mdColor_brownA700 {
    background-color: #5d4037;
}

.mdColor_grey50 {
    background-color: #fafafa;
}

.mdColor_grey100 {
    background-color: #f5f5f5;
}

.mdColor_grey200 {
    background-color: #eeeeee;
}

.mdColor_grey300 {
    background-color: #e0e0e0;
}

.mdColor_grey400 {
    background-color: #bdbdbd;
}

.mdColor_grey500 {
    background-color: #9e9e9e;
}

.mdColor_grey600 {
    background-color: #757575;
}

.mdColor_grey700 {
    background-color: #616161;
}

.mdColor_grey800 {
    background-color: #424242;
}

.mdColor_grey900 {
    background-color: #212121;
}

.mdColor_grey1000 {
    background-color: #000000;
}

.mdColor_greyA100 {
    background-color: #ffffff;
}

.mdColor_greyA200 {
    background-color: #eeeeee;
}

.mdColor_greyA400 {
    background-color: #bdbdbd;
}

.mdColor_greyA700 {
    background-color: #616161;
}

.mdColor_blueGrey-50 {
    background-color: #eceff1;
}

.mdColor_blueGrey-100 {
    background-color: #cfd8dc;
}

.mdColor_blueGrey-200 {
    background-color: #b0bec5;
}

.mdColor_blueGrey-300 {
    background-color: #90a4ae;
}

.mdColor_blueGrey-400 {
    background-color: #78909c;
}

.mdColor_blueGrey-500 {
    background-color: #607d8b;
}

.mdColor_blueGrey-600 {
    background-color: #546e7a;
}

.mdColor_blueGrey-700 {
    background-color: #455a64;
}

.mdColor_blueGrey-800 {
    background-color: #37474f;
}

.mdColor_blueGrey-900 {
    background-color: #263238;
}

.mdColor_blueGrey-A100 {
    background-color: #cfd8dc;
}

.mdColor_blueGrey-A200 {
    background-color: #b0bec5;
}

.mdColor_blueGrey-A400 {
    background-color: #78909c;
}

.mdColor_blueGrey-A700 {
    background-color: #455a64;
}
