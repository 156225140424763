.dataTable {
    width: 100%;
    margin: 20px 0;
}

.dataTable caption {
    padding: 0 0 10px;
    font-size: 16px;
    font-weight: bold;
}

.dataTable thead tr {
    background-color: rgb(211, 211, 211);
}

.dataTable th,
.dataTable td {
    padding: 5px;
    text-align: left;
}

.dataTable thead th {

}

.dataTable tbody td {

}

.dataTable tbody tr:nth-child(even) {
    background-color: rgb(235, 235, 235);
}
