.actionableItem {
    display: flex;
}

.actionableItem-item {
    flex: 1;
}

.actionableItem-buttons {
    text-align: right;
}
