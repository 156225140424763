html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    vertical-align: baseline;
    color: inherit;
}

/*body {
    line-height: 1;
}*/

ol, ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:before, blockquote:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

:focus {
    outline: 0;
}

/* Always show a vertical scrollbar, even when there is no scrolling */
/*html {
    overflow-y: scroll;
}*/

/* Don't let iOS and WinMobile mobile-optimize text for you */
// html {
//     -webkit-text-size-adjust: none;
//     -ms-text-size-adjust: none;
// }

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
    display: block;
}

audio, canvas, video {
    display: inline-block;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

button,
input[type="submit"],
input[type="img"] {
    cursor: pointer;
}

/* make button element reset properly in FF */
::-moz-focus-inner {
    border: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre {
    display: block;
    background: transparent;
    letter-spacing: normal;
    width: auto;
    height: auto;
    line-height: inherit;
    text-transform: inherit;
    color: inherit;
}
