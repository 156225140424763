.notifications {
    display: flex;
    flex-direction: column;
    clear: both;
}

.notifications-input {
    padding: 10px 0;
}

.notifications-chips {
    padding: 0 0 10px;
}

.notifications-bd {
    flex-grow: 1;
    position: relative;
    overflow: auto;
    margin-right: -20px;
}
