.maskedScrollWrapper {
    position: relative;

    &:after {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 25px;
        pointer-events: none;
        background: linear-gradient(0deg, rgba(246, 247, 249, 1) 0%, rgba(246, 247, 249, 0) 100%);
    }
}
