#addOrderItemTable {
    table-layout: fixed;
}

#addOrderItemTable td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.addOrderItemTable--name { width: 18.96551724137931%; } /* 220/1160 */
.addOrderItemTable--sku { width: 10.344827586206896%; } /* 120/1160 */
.addOrderItemTable--productId {}
.addOrderItemTable--type { width: 6.896551724137931%; } /* 80/1160 */
.addOrderItemTable--description { width: 35%; }
.addOrderItemTable--fulfillmentCenter { width: 13.36206896551724%; } /* 155/1160 */

#addOrderItemShipmentsTable {
    table-layout: fixed;
}

.addOrderItemShipmentsTable--radio { width: 20px; }
.addOrderItemShipmentsTable--name {}
.addOrderItemShipmentsTable--date { width: 115px; }
.addOrderItemShipmentsTable--items {}

.leftCells > th,
.leftCells > td {
    text-align: left !important;
}
