.inlinePipeList {
    @include clearfix;
    @include list-reset;

    display: inline-block;

    > * {
        float: left;
    }

    > * + * {
        border-left: 2px solid;
        margin-left: 0.32em;
        padding-left: 0.32em;
    }
}
