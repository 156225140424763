.actionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.actionHeader-hd {
    order: 1;
    flex-grow: 1;
}

.actionHeader-actions {
    order: 2;
    flex-grow: 0;
    margin-left: 15px;
}
