.alertToggle_loading {
    margin-left: 7px;
}

.alertToggle {
    padding-right: 20px;
    background: transparent url("/web3/build/admin/images/alert-sprite.png") no-repeat right top;

    &:hover,
    &:active {
        background-position: right bottom;
    }
}

.alertToggle_off {
    background-position: right bottom;

    &:hover,
    &:active {
        background-position: right top;
    }
}
