/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper { position: absolute; top: 0; left: 0; z-index: 9999; overflow: hidden; }
#cboxWrapper { max-width: none; }
#cboxOverlay { position: fixed; width: 100%; height: 100%; }

#cboxMiddleLeft,
#cboxBottomLeft { clear: left; }
#cboxContent { position: relative; }
#cboxLoadedContent { overflow: auto; -webkit-overflow-scrolling: touch; }
#cboxTitle { margin: 0; }

#cboxLoadingOverlay,
#cboxLoadingGraphic { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow { cursor: pointer; }
.cboxPhoto { float: left; margin: auto; border: 0; display: block; max-width: none; -ms-interpolation-mode: bicubic; }
.cboxIframe { width: 100%; height: 100%; display: block; border: 0; }

#colorbox,
#cboxContent,
#cboxLoadedContent { box-sizing: content-box; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; }

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay { background: #000000; }
#colorbox { outline: 0; }
#cboxTopLeft { width: 25px; height: 25px; background: url(/web3/build/base/images/border1.png) no-repeat 0 0; }
#cboxTopCenter { height: 25px; background: url(/web3/build/base/images/border1.png) repeat-x 0 -50px; }
#cboxTopRight { width: 25px; height: 25px; background: url(/web3/build/base/images/border1.png) no-repeat -25px 0; }
#cboxBottomLeft { width: 25px; height: 25px; background: url(/web3/build/base/images/border1.png) no-repeat 0 -25px; }
#cboxBottomCenter { height: 25px; background: url(/web3/build/base/images/border1.png) repeat-x 0 -75px; }
#cboxBottomRight { width: 25px; height: 25px; background: url(/web3/build/base/images/border1.png) no-repeat -25px -25px; }
#cboxMiddleLeft { width: 25px; background: url(/web3/build/base/images/border2.png) repeat-y 0 0; }
#cboxMiddleRight { width: 25px; background: url(/web3/build/base/images/border2.png) repeat-y -25px 0; }
#cboxContent { background: #ffffff; overflow: hidden; }
.cboxIframe { background: #ffffff; }
#cboxError { padding: 50px; border: 1px solid #cccccc; }
#cboxLoadedContent { margin: 20px; }
#cboxTitle { position: absolute; bottom: 0; left: 0; text-align: center; width: 100%; color: #999999; }
#cboxCurrent { position: absolute; bottom: 0; left: 100px; color: #999999; }
#cboxLoadingOverlay { background: #ffffff url(/web3/build/base/images/loading.gif) no-repeat 5px 5px; }

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose { border: 0; padding: 0; margin: 0; overflow: visible; width: auto; background: none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active { outline: 0; }

#cboxSlideshow { position: absolute; bottom: 0; right: 42px; color: #444444; }
#cboxPrevious { position: absolute; bottom: 0; left: 0; color: #444444; }
#cboxNext { position: absolute; bottom: 0; left: 63px; color: #444444; }

/* #cboxClose{position:absolute; bottom:0; right:0; display:block; color:#444;}
        my version displays at top with an X image */
#cboxClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    text-indent: -9999px;
    font-size: 0;
    outline: 0;
    background: transparent url(/web3/build/base/images/close-modal.png) 0 0 no-repeat;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
#cboxClose:hover { opacity: 0.5; }

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:dximagetransform.microsoft.gradient(startColorstr=#00ffffff, endColorstr=#00ffffff);
}
