$chipHeight: 32px;
$buttonDimensions: 12px;
$chipHeight_sm: 20px;
$buttonDimensions_sm: 10px;

@mixin addChipDimensions ($height) {
    height: $height;
    border-radius: $height / 2;
}

.chip {
    @include addChipDimensions(($chipHeight));

    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    max-width: 100%;
    line-height: normal;
    padding: 0 8px 0 12px;
    background: rgb(224, 224, 224);
    color: rgb(66, 66, 66);
    cursor: default;
}

.chip-name {
    max-width: 100%;
    padding-right: $chipHeight / 4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chip-btn {
    width: $buttonDimensions;
    height: $buttonDimensions;
}

.chip-btn button {
    height: $buttonDimensions;
}

.chip_sm {
    @include addChipDimensions($chipHeight_sm);
    font-size: 10px;
    padding-left: $chipHeight_sm / 2;

    .chip-name {
        padding-right: $chipHeight_sm / 4;
    }

    .chip-btn {
        width: $buttonDimensions_sm;
        height: $buttonDimensions_sm;

        button {
            height: $buttonDimensions_sm;
        }
    }
}
