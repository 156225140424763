.contentPreview {
    display: flex;
    width: 225px;
}

.contentPreview-label {
    color: #aaaaaa;
}

.contentPreview-content {
    margin: 0 0 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.contentPreview-content_empty {
    font-style: italic;
    color: #aaaaaa;
}

.contentPreview-action {
    flex-grow: 0;
}
