@debug "///////////////////// [\"ADMIN\"] CSS TODOs: /////////////////////"; // leave note in the console
// /* Important! @import font file(s) must appear first in this file */
@import "../../base/css/fonts/proxima-nova";
@import "../../base/fonts/fontello/css/fontello";
@import "../../base/css/third-party/fontello-override";
@import url("https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap");

////////////////////////////////////////////////////////////////////////////////////////////////
// Base
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../base/css/common";
@import "../../base/css/mixins";
@import "../../base/css/animations";

////////////////////////////////////////////////////////////////////////////////////////////////
// Third Party
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../base/css/third-party/bootstrap/variable-overrides";
@import "third-party/bootstrap";
@import "../../base/css/third-party/bootstrap/theme";
@import "../../base/css/third-party/bootstrap/typeahead-custom";
@import "../../../../../bower_components/jquery-ui/themes/smoothness/jquery-ui";
@import "../../../../../bower_components/jquery-ui/themes/smoothness/theme";
// @import "../../../../../bower_components/select2/dist/css/select2.min";
@import "../../web3/js/ng/vendor/select2/select2";
@debug "TODO: Using older version of select2 - see about updating in the future. New version caused isses, see release notes at https://select2.github.io/announcements-4.0.html";
@import "../../javascriptLib/jquery-plugins/colorpicker-23.05.2009/css/colorpicker";
@import "../../web3/css/colorbox";
@debug "TODO: Colorbox deprecated in ADMIN. Replace with UI Modal where necessary.";
@import "../../web3/js/ng/common/directives/plupload/plupload";
@import "../../base/css/third-party/angucomplete/angucomplete";
@import "../../../../../bower_components/intl-tel-input/build/css/intlTelInput";
@import "../../base/css/third-party/intlTelInput.scss";

////////////////////////////////////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../base/css/960";
@import "../../administration/css/grid-responsive";

////////////////////////////////////////////////////////////////////////////////////////////////
// Legacy
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../administration/css/admin";
@import "../../web3/css/main";

////////////////////////////////////////////////////////////////////////////////////////////////
// Modules
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../base/css/modules/actionable-item";
@import "../../base/css/modules/action-header";
@import "../../base/css/modules/alert-toggle";
@import "../../base/css/modules/block-btn";
@import "../../base/css/modules/block-filters";
@import "../../base/css/modules/btn-plain";
@import "../../base/css/modules/bubble";
@import "../../web3/js/ng/common/directives/bulk-actions/bulk-actions";
@import "../../base/css/modules/cells";
@import "../../base/css/modules/chip";
@import "../../web3/js/ng/common/collaterate-info/collaterate-info";
@import "../../base/css/modules/color-chip";
@import "../../base/css/modules/content-preview";
@import "../../base/css/modules/csr-notifications";
@import "../../base/css/modules/css-caret";
@import "../../base/css/custom-elements";
@import "../../base/css/modules/data-table";
@import "../../base/css/modules/date-sign";
@import "../../base/css/modules/h-list";
@import "../../base/css/modules/hdg";
@import "../../base/css/modules/i-list";
@import "../../base/css/modules/inline-pipe-list";
@import "../../base/css/modules/input-radio";
@import "../../base/css/modules/input-suffix";
@import "../../web3/js/ng/common/directives/input-toggle/input-toggle";
@import "../../base/css/modules/instructions";
@import "../../base/css/modules/list-bordered";
@import "../../base/css/modules/list-plain";
@import "../../base/css/modules/location-tag";
@import "../../base/css/modules/masked-scroll-wrapper";
@import "../../base/css/modules/message-bar";
@import "../../web3/js/ng/common/color/md-color";
@import "../../base/css/modules/median";
@import "../../web3/js/ng/common/directives/multichooser/multichooser";
@import "../../base/css/modules/package";
@import "../../base/css/modules/page";
@import "../../base/css/modules/pricing-contract-table";
@import "../../web3/js/ng/common/directives/popup/popupImage";
@import "../../base/css/modules/product-info-bar";
@import "../../base/css/modules/quoted";
@import "../../base/css/modules/quote-status";
@import "../../base/css/modules/site-listing";
@import "../../base/css/modules/sortable-group";
@import "../../base/css/modules/tables";
@import "../../base/css/modules/tabs";
@import "../../base/css/modules/theme";
@import "../../base/css/modules/toggle-group";
@import "../../base/css/modules/tree-view";
@import "../../base/css/modules/truncated-cells";
@import "../../base/css/modules/typeaheads";
@import "../../base/css/modules/wysiwyg";

////////////////////////////////////////////////////////////////////////////////////////////////
// Print Styles (IE9+, if older support is needed, move back to explicit declaration in <head>)
////////////////////////////////////////////////////////////////////////////////////////////////
@media print {
    @import "../../administration/css/printJobTicket";
    @import "../../administration/css/print";
}

@debug "TODO: refactor CSS includes in admin sections of application due to high level of repitition between both: (example in source)";

/*
////////////////////////////////////////////////////////////////////////////////////////////////
// Base
////////////////////////////////////////////////////////////////////////////////////////////////
// for each section, identify the common includes:
@import "../../base/css/common"
    > common
    > mixins
// then import custom includes:
@import "../../custom-mod-1"
@import "../../custom-mod-2"
 */
