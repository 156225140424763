.hdg {
    font-weight: normal;
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
    margin: 0;
    @debug "TODO: remove margin from .hdg once base heading elements have been cleaned";
}

.hdg_1 {
    font-size: 38px;
    font-size: 3.8rem;
    line-height: (42 / 38); // targetLineHeightPx / fontSizePx = unitless line height value
}

.hdg_2 {
    font-size: 26px;
}

.hdg_5 {
    font-family: "Proxima Nova Soft W03 Reg", Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif;
    font-size: 15px;
    font-weight: bold;
}
