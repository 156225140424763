.angucomplete-holder {
    position: relative;
}

.angucomplete-dropdown {
    border-color: #cccccc;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    width: 100%;
    // padding: 6px;
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    max-height: 300px;
    overflow-y: auto;
}

.angucomplete-searching {
    color: #acacac;
    font-size: 14px;
    padding: 5px;
}

.angucomplete-description {
    font-size: 14px;
}

.angucomplete-row {
    padding: 5px;
    color: #000000;
    clear: both;
}

.angucomplete-row + .angucomplete-row {
    margin-top: 5px;
}

.angucomplete-selected-row {
    background-color: #cccccc;
    color: #ffffff;
}

.angucomplete-image-holder {
    padding-top: 2px;
    float: left;
    margin-right: 10px;
    margin-left: 5px;
}

.angucomplete-image {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
}

.angucomplete-image-default {
    /* Add your own default image here
     background-image: url('/assets/default.png');
    */
    background-position: center;
    background-size: contain;
    height: 34px;
    width: 34px;
}
