input[type="radio"],
input[type="checkbox"] {
    margin: 4px 3px 0 0;
    margin-top: 1px \9;
}

input[type="file"] {
    display: inline-block;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}

select.form-control {
    transition: none; /* fix flickering on select menus */
}

.control-label {
    opacity: 0.6;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    opacity: 1;
}


.field-error {
    background: transparent url("/web3/build/admin/images/alert.png") no-repeat 0 0;
    padding-left: 20px;
}


.field-info {
    background: transparent url("/web3/build/admin/images/information.png") no-repeat 0 0;
    padding-left: 20px;
}

.form-error {
    padding: 10px 15px;
    border: 1px solid #a10d25;
    text-align: center;
    background: rgba(255, 0, 0, 0.05);
    border-radius: 3px;
    margin: 15px 0;

    .field-error {
        display: inline-block;
        text-align: left;
    }
}

.has-error textarea {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.fieldset + .fieldset {
    margin-top: 30px;
}

.form-control[disabled] {
    color: rgba(85, 85, 85, 0.65);
}

textarea.form-control {
    min-width: 100%;
    max-width: 100%;
    height: 170px;
}
