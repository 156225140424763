.bubble {
    $_bubble_bgColor: rgba(255, 255, 255, 0.95);
    $_bubble_arrowWidth: 13px;

    position: absolute;
    width: 300px;
    padding: 12px;
    background: $_bubble_bgColor;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    box-shadow: 0 3px 12px -1px rgba(0, 0, 0, 0.2);
    left: calc(100% + #{$_bubble_arrowWidth});
    top: -1px;

    &:after {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        right: 100%;
        top: 10px;
        border-top: 1px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: $_bubble_arrowWidth solid $_bubble_bgColor;
    }
}

.bubble-close {
    float: right;
    margin-left: 8px;
}

.bubble-title {
    overflow: hidden; // new block formatting context
}

.bubble-list {
    @include list-reset;
    clear: both;
    padding-top: 15px;

    & > li {
        padding: 0;
        margin: 0;

        a {
            cursor: pointer;
        }
    }

    & > li + li {
        margin-top: 4px;
    }
}
